<template>
  <div class="contact_us">
    <div class="con_nav">
      <nav-tab :nav-data="navData" />
    </div>
    <div class="con_center">
      <FaqList />
    </div>
    <div class="con_bottom">
      <layout-bottom />
    </div>
  </div>
</template>

<script>
import NavTab from '@/components/NavTab'
import LayoutBottom from '@/components/LayoutBottom'
import FaqList from '@/components/faq/faqList'
export default {
  components: {
    NavTab,
    FaqList,
    LayoutBottom
  },
  data() {
    return {
      navData: {
        index: 2,
        list: [
          {
            name: 'Hogar',
            link: '/'
          },
          {
            name: 'Sobre nosotros',
            link: '/loan'
          },
          {
            name: 'Proceso',
            link: '/faq'
          }
          // {
          //   name: 'HOW IT WORKS',
          //   link: '/how_it_works'
          // },
          // {
          //   name: 'Proceso',
          //   link: '/faq'
          // },
          // {
          //   name: 'CONTACT US',
          //   link: '/contact_us'
          // }
        ]
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 768px){
  .con_center{
    // padding-top: 60px;
  }
}
@media only screen and (max-width: 767px){
  .con_nav{
    height: 123px;
    background: #fff;
  }
}
</style>
